import { ReactNode, useEffect, useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import cn from 'classnames';
import { useCurrentRefinements, useHits, useSearchBox } from 'react-instantsearch';
import { useRouter } from 'next/router';
import { m, MotionProps } from 'framer-motion';
import { useFilterContext } from '@context';
import { LARGE_WIDTH, MEDIUM_LARGE_WIDTH, REFINEMENT_FILTER_COLORS } from '@constants';
import { Button, Filter, Flex, PLPWrapper, TopFrameEducation } from '@components';
import { Body, Heading, Label, TypographyButton } from '@components/typography';
import useWindowDimensions from '@utils/hooks/useWindowDimensions';
import variables from '@styles/export.module.scss';
import { useLocalStorage } from '@utils/hooks';
import { hexToRGBA } from '@utils/colors';
import { CopyFields } from '@ts/contentful';
import { accordianHeightExpansion } from '@utils/motions';
import styles from './FilterHeader.module.scss';

const CurrentRefinements = dynamic(() => import('@components').then(mod => mod.CurrentRefinements));

const FilterHeader = ({ children, plpAccordion }: { children?: ReactNode, plpAccordion?: CopyFields; }) => {
	const [allTopsTitle, setAllTopsTitle] = useLocalStorage('allTopsTitle', 'All Tops');
	const [currentTitle, setCurrentTitle] = useState(allTopsTitle);
	const { results } = useHits();
	const { query } = useSearchBox();
	const { setIsOpen, isOpen, type, setIsHeaderBigger } = useFilterContext();
	const { items } = useCurrentRefinements();
	const { width: windowWidth } = useWindowDimensions();

	const isSearch = type === 'search';
	const isAllTops = type === 'all-tops';
	const isBuildflow = type === 'buildflow';
	const isBFAllTops = type === 'bf-all-tops';
	const isDesktop = windowWidth > LARGE_WIDTH;
	const isTablet = windowWidth < MEDIUM_LARGE_WIDTH;

	const { query: queryParams } = useRouter();

	useEffect(() => {
		const valuesQuery = Object.values(queryParams);
		if ((valuesQuery.length == 0 || valuesQuery.length > 1) && allTopsTitle !== 'All Tops') {
			setAllTopsTitle('All Tops');
			setCurrentTitle('All Tops');
		}
	}, [queryParams]);

	const backgroundColor = useMemo(() => {
		if (!isAllTops) {
			return null;
		}
		if (currentTitle === 'All Tops') {
			return null;
		}
		if (currentTitle === 'Rainbow Tops') {
			return {
				backgroundImage: `conic-gradient(
				rgba(20, 200, 38, 0.1),
				rgba(0, 163, 255, 0.1),
				rgba(20, 0, 255, 0.1),
				rgba(255, 0, 0, 0.1),
				rgba(230, 234, 8, 0.1),
				rgba(20, 200, 38, 0.1)
			  )` }
		}
		const headingColor = REFINEMENT_FILTER_COLORS[currentTitle.split(' ')[0].toUpperCase()];
		return { backgroundColor: hexToRGBA(headingColor, 0.1) };
	}, [currentTitle, isAllTops]);

	const headingJsx = (
		<Flex align='center' className={styles['heading']} fullWidth>
			{isSearch && (
				<div className={styles['search-heading']}>
					<Heading tag='h5' >
						Search results for:
					</Heading>
					<Body>{` ${query ? `"${query}"` : ''} (${results.nbHits})`}</Body>
				</div>

			)}
			{(isAllTops || isBFAllTops) && <Heading tag='h4' fixedSize style={{ marginBottom: '0' }}>{currentTitle}</Heading>}
			{isBuildflow && <Label {...(!isDesktop && { small: true })}>Top Frames</Label>}
			{!isSearch && <TypographyButton small={(!isDesktop && !isBFAllTops)} style={{ color: variables.greenShade }} data-testid='tops-counter'>
				{results?.nbHits > 0 ? results?.nbHits : 0}
			</TypographyButton>}
		</Flex>
	);

	const filterButton = (
		<div data-filter-button={type} className={cn(styles.filtersButton, { [styles.filtersButtonBfTops]: isBFAllTops })}>
			<Button color='white' data-open-filter={isOpen} onClick={() => { setIsOpen(!isOpen) }} fullWidth>
				<Filter color='currentColor' style={{ maxWidth: 'fit-content' }} />
				<span>{`${isOpen ? 'Hide' : 'Show'} Filters`}</span>
			</Button>
		</div>
	);

	return (
		<m.div {...(accordianHeightExpansion as MotionProps)} className={cn(styles['wrapper'], { [styles['all-top-wrapper']]: isBFAllTops })}>
			<Flex align={isAllTops && !!plpAccordion ? 'end' : 'center'} justify='between' column={isAllTops && !!plpAccordion} style={backgroundColor} className={cn(styles['container'], { [styles['buildflow']]: isBuildflow, [styles['container--search']]: isSearch, [styles['hidden']]: isSearch && results.nbHits === 0 })}>
				{(isAllTops && !!plpAccordion) ? (
					<PLPWrapper.Accordion
						plpAccordion={plpAccordion}
						titleChildren={headingJsx}
						onOpenChange={setIsHeaderBigger}
						isAllTops
					/>
				) : headingJsx}
				{isAllTops || isSearch ? (
					<>
						{!isDesktop && (
							filterButton
						)}
						{children}
					</>
				) : (
					isBFAllTops ? (
						filterButton
					) : (
						<>
							{isTablet && <TopFrameEducation classes={{ trigger: styles['top-frame-education'] }} isMobile={isTablet} />}
							<div className={styles['filters']}>
								{children}
							</div>
							<CurrentRefinements className={cn(styles['refinements'], { [styles['show-refinements']]: !!items?.length })} />
						</>
					)
				)}
			</Flex>
		</m.div>
	);
};

export default FilterHeader;
