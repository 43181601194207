import { forwardRef, ReactNode } from 'react';
import { AnimatePresence, m, MotionProps } from 'framer-motion';
import cn from 'classnames';
import { Caption, Heading, Paragraph } from '@components/typography';
import { Minus, Plus } from '@components';
import { accordianHeightExpansion, rotateVariants } from '@utils/motions';

import styles from './Accordian.module.scss';

type titleFormat = 'heading' | 'paragraph' | 'caption';

type headingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

type AccordianProps = {
	title: string;
	titleFormat?: titleFormat;
	titleChildren?: ReactNode;
	children: ReactNode;
	className?: string;
	isOpen?: boolean;
	onClick: () => void;
	customRow?: string;
	headingTag?: headingTag;
};

const getTitle = (titleFormat: titleFormat, title: string, headingTag?: headingTag) => {
	switch (titleFormat) {
		case 'caption':
			return <Caption>{title}</Caption>
		case 'heading':
			return <Heading tag={headingTag ?? 'h6'} removeDefaultMargin>{title}</Heading>;
		case 'paragraph':
			return <Paragraph style={{ maxWidth: '25rem' }}>{title}</Paragraph>;
		default:
			return <Heading tag={headingTag ?? 'h6'}>{title}</Heading>;
	}
}

const Accordian = forwardRef<HTMLDivElement, AccordianProps>(({ title, titleFormat = 'heading', titleChildren, children, className = '', isOpen, onClick, customRow, headingTag }, ref) => {
	return (
		<m.div ref={ref} className={cn(styles.container, className)} data-accordion={title}>
			<m.div className={cn(styles.row, customRow)} onClick={onClick}>
				{getTitle(titleFormat, title, headingTag)}
				{titleChildren}
				<m.div animate={isOpen ? 'rotateL' : 'rotateR'} variants={rotateVariants}>
					{isOpen ? <Minus /> : <Plus />}
				</m.div>
			</m.div>
			<AnimatePresence>
				{isOpen && <m.div {...(accordianHeightExpansion as MotionProps)}>{children}</m.div>}
			</AnimatePresence>
		</m.div>
	);
});

Accordian.displayName = 'Accordian';

export default Accordian;
