import { useCallback, useEffect } from 'react';
import {
	Configure,
	useCurrentRefinements,
	useInfiniteHits,
	useInstantSearch,
	usePagination,
	useSearchBox,
} from 'react-instantsearch';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createInfiniteHitsSessionStorageCache } from 'instantsearch.js/es/lib/infiniteHitsCache';
import { m } from 'framer-motion';
import { VirtuosoGrid } from 'react-virtuoso';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { useFilterContext } from '@context';
import { Container, Flex, Loading } from '@components';
import { Heading, Paragraph } from '@components/typography';
import Hit from '../Hit/Hit';
import NoResults from '../NoResults';
import styles from './InfiniteHits.module.scss';

const sessionStorageCache = createInfiniteHitsSessionStorageCache();

/**
 * InfiniteHits
 *
 * @description Custom version of Algolia's "InfiniteHits" component using the "useInfiniteHits" hook.
 * @source https://www.algolia.com/doc/api-reference/widgets/infinite-hits/react/
 *
 * !!IMPORTANT!!
 * Do NOT use the Next Router's "useRouter" hook in this component; it will break InstantSearch SSR Provider.
 */
const InfiniteHits = () => {
	const { status } = useInstantSearch();
	const { refine, currentRefinement } = usePagination();
	const {
		hits: hitsData,
		showMore,
		results,
		isLastPage,
	} = useInfiniteHits({
		cache: sessionStorageCache,
	});
	const { items } = useCurrentRefinements();
	const { frameShape, isSunglassesRoute, isOpen, type, virtuosoRef } = useFilterContext();
	const { locale } = useRouter();
	const isBuildflow = type === 'buildflow' || type === 'bf-all-tops';
	const isSearch = type === 'search';
	const { query, refine: searchRefine } = useSearchBox();
	let hits = hitsData;
	const hitsLength = hits.length;
	// Filters out Sun Tops on the '/sunglasses' route
	if (isSunglassesRoute)
		hits = hits.filter(hit => {
			const foundHandle = (hit?.handle as string) ?? false;

			if (foundHandle && foundHandle.includes('sun-top')) return;
			else return hit;
		});

	useEffect(() => {
		refine(0); // Reset pagination
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOnScroll = useCallback(() => {
		if (isLastPage) return;
		showMore();
	}, [isLastPage, showMore]);

	const resultFilters = useCallback(
		() => `option_names: frame AND options.frame: ${frameShape} AND inventory_available: true`,
		[frameShape]
	);

	const loadingJsx = status === 'loading' && (
		<Flex className={styles['loading-container']}>
			<Flex column align='center'>
				<Loading className={styles['loading']} phrase='Loading Results...' />
			</Flex>
		</Flex>
	);

	const stalledJsx = status === 'stalled' && hitsLength === 0 && (
		<Container className={styles['no-results']}>
			<Heading tag='h5'>No results found.</Heading>
			<Paragraph>Try a different color, design, or collection.</Paragraph>
		</Container>
	);

	return (
		<>
			{!isSearch && <Configure hitsPerPage={50} filters={resultFilters()} />}
			<m.div
				className={cn(styles['container'], {
					[styles['buildflow']]: isBuildflow,
					[styles['show-refinements']]: !!items?.length,
				})}
			>
				{isSearch && results.nbHits === 0 && <NoResults query={query} handleSearch={searchRefine} />}
				{loadingJsx}
				<VirtuosoGrid
					ref={virtuosoRef}
					style={{ minHeight: isBuildflow ? '100%' : '100vh' }}
					className={styles['virtuoso']}
					listClassName={cn(styles['tops'], {
						[styles['tops--double']]: isBuildflow,
						[styles['tops--is-open']]: !isBuildflow && isOpen,
					})}
					data={hits}
					totalCount={results.nbHits}
					overscan={{ main: 1000, reverse: 2000 }}
					useWindowScroll={!isBuildflow}
					endReached={handleOnScroll}
					itemContent={(index, hit) =>
						hit ? <Hit locale={locale} key={hit.objectID} hit={hit} position={currentRefinement + index + 1} type={type} /> : null
					}
				/>
				{stalledJsx}
			</m.div>
		</>
	);
};

export default InfiniteHits;
