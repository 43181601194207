import cn from 'classnames';
import { Button, Flex, InformationIcon, Modal } from '@components';
import { Heading, Paragraph } from '@components/typography';
import { slideUp } from '@utils/motions';
import styles from './TopFrameEducation.module.scss';

type TopFrameEducationProps = {
	classes?: Partial<{ trigger?: string; content?: string }>;
	isMobile?: boolean;
};

const TopFrameEducation = ({ classes = { trigger: null, content: null }, isMobile }: TopFrameEducationProps) => {
	// TODO - The text are hard code for now, will migrate to contentful later on
	return (
		<Modal>
			<Modal.Trigger asChild>
				<Flex justify='center' className={cn(styles.button, { [classes.trigger]: classes.trigger })} data-top-frame-education>
					<Button linkStyle size='small'>
						<InformationIcon width={isMobile && 16} height={isMobile && 16} />
						What Are Top Frames?
					</Button>
				</Flex>
			</Modal.Trigger>
			<Modal.Content className={styles.modal} customAnimation={slideUp}>
				<div>
					<div>
						<Heading tag='h5'>What Are Top Frames?</Heading>
						<Paragraph>
							{`This is where 'One Pair of Glasses, Infinite Possibilities' comes into play. Add personality to your
						Pair with hundreds of swappable Top Frames that instantly snap onto your Base Frame. Switch up your
						everyday style with unique patterns, bold colors, Sun Tops, and classic staples!`}
						</Paragraph>
					</div>
					<div className={styles.sectionContainer}>
						<Heading tag='h6'>How Do Top Frames Attach to Base Frames?</Heading>
						<Paragraph>
							The magic is in the magnets. Two invisible, ultra-lightweight magnets (we’re talking {'< 1'} gram), cover
							the top left and right corners of the frames, firmly attaching the lensless Top Frame or lensed Sun Top
							Frame to the lensed Base Frame.
						</Paragraph>
					</div>
					<div className={styles.sectionContainer}>
						<Heading tag='h6'>What Are Top Frames Made Out Of?</Heading>
						<Paragraph>Durable, injected-molded plastic.</Paragraph>
					</div>
					<div className={styles.sectionContainer}>
						<Heading tag='h6'>How Thick Are Top Frames?</Heading>
						<Paragraph>
							2mm, to be exact. Our goal is to have our Pair Family seeing 20-20, so our Tops will not obstruct your
							vision.
						</Paragraph>
					</div>
					<div className={styles.sectionContainer}>
						<Heading tag='h6'>How Heavy Is a Top Frame?</Heading>
						<Paragraph>
							The weight of a single Top Frame ranges from 2.6 to 3.5 grams, depending on the model. That’s lighter than
							a teaspoon of sugar! Our Sun Tops will typically add an additional 2-3 grams due to their lenses.
						</Paragraph>
					</div>
				</div>
				<Modal.Close asChild>
					<div style={{ width: '100%', padding: '2.4rem 0 1.6rem' }}>
						<Button color='white' style={{ width: '100%' }}>
							Close
						</Button>
					</div>
				</Modal.Close>
			</Modal.Content>
		</Modal>
	);
};

export default TopFrameEducation;
